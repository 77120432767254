import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import LuxuryCar from "../components/LuxuryCar"

const LuxuryCarPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb textShadow={true} bgImageOverlay={true} slug={fields.slug} title={fields.title} bgImage={bgImage} bgPadding="8em" />
          <LuxuryCar data={fields} content={data.page.html} />
      </Layout>
    );
};
export default withI18next()(LuxuryCarPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      html
      frontmatter {
        slug
        title
        pageTitle
        pageSubtitle
        questionTitle
        questionDescription
        questionPhone
        questionEmail
        blockquote
        date
        description
        cars {
            make
            model
            airportTransfer
            rentBudapest
            rentCountryside
            image {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 70) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "bg/bentley_interior_door_panel.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  }`